import { createSlice } from '@reduxjs/toolkit';
import {getOrderInvoiceAction, getOrderInvoiceSucceededAction, markDirtyOrderInvoiceAction} from './Actions';
import { orderInvoiceInitialState } from './State';
import {getOrderInvoiceReducer, getOrderInvoicesSucceededReducer, markDirtyOrderInvoiceReducer,} from './Reducers';

export const orderInvoiceSlice = createSlice({
  name: 'orderInvoices',
  initialState: orderInvoiceInitialState,
  reducers:{},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderInvoiceSucceededAction, getOrderInvoicesSucceededReducer)
      .addCase(getOrderInvoiceAction, getOrderInvoiceReducer)
      .addCase(markDirtyOrderInvoiceAction, markDirtyOrderInvoiceReducer)
  },
});
