import {DormerRootState} from "../DormerRootState";
import {FaceConfigurationDto, Side} from "@hec/api-dtos";
import {getConfiguredFramesSelector} from "./FrameSelectors";
import {getCheeks, getSplinter} from "./SectionFragmentSelectors";
import {getSplinterFullHorizontalWidth, getWidthOfStaticSections} from "@hec/core";

export const getFaceConfiguration = (state: DormerRootState): FaceConfigurationDto | null => {
  if (state.order.order !== null) {
    return state.order.order.faceConfiguration;
  }
  return null;
}

export const getDormerTotalWidth = (state: DormerRootState): number | null => {
  if (state.order.order === null || state.order.order.faceConfiguration === null) {
    return null;
  }

  const configuredFrames = getConfiguredFramesSelector(state);
  const cheeks = getSplinter(state);
  const splinter = getCheeks(state);

  const framesTotalWidth = configuredFrames.reduce((acc, frame) => acc + frame.width, 0);
  let currentTotalWidth: number;
  if (cheeks !== null && splinter !== null) {
    currentTotalWidth = framesTotalWidth + (splinter.width * 2) + (cheeks.width * 2);
  } else {
    currentTotalWidth = framesTotalWidth;
  }

  return currentTotalWidth;
}

export const getDisplaySizeInformation = (state: DormerRootState) => {
  const faceConfiguration = getFaceConfiguration(state);

  const displayWidth = faceConfiguration?.width ?? 0;
  const southFace = faceConfiguration?.faces?.find(x => x.side === Side.South);

  const widthOfStaticSections = getWidthOfStaticSections(southFace);

  const totalWidthOfFrames = displayWidth - widthOfStaticSections + getSplinterFullHorizontalWidth(southFace);

  const result = {
    totalDormerWidth: displayWidth.toString(),
    totalFrameWidth: totalWidthOfFrames.toString()
  }

  return result;
}
