import React, { FunctionComponent } from 'react';
import styles from './cost-aggregate.module.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {DormerModalContentKey, TranslationKeys} from '@hec/models';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import {putOrderAction, storeUiStatusAction} from "@hec/dal";
import {GetOrderInvoiceViewModel} from "@hec/api-dtos";
import {Spinner} from "./Spinner";

export interface CostAggregateProps {
  invoice?: GetOrderInvoiceViewModel;
  hidden?: boolean;
  hidePrice?: boolean;
  modalOpen?: boolean;
  orderIsDirty: boolean;
  orderInvoiceIsDirty: boolean;
}

export const CostAggregate: FunctionComponent<CostAggregateProps> = ({
                                                                       orderIsDirty,
                                                                       orderInvoiceIsDirty,
  invoice,
  hidden = false,
  hidePrice = false,
  modalOpen = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = orderIsDirty || orderInvoiceIsDirty;

  return (
    <>
      <div
        className={classnames(
          styles.costAggregate,
          {
            [`${styles['-active']}`]: modalOpen,
            [`${styles.hidden}`]: hidden,
          }
        )}
        onClick={() => {
          dispatch(putOrderAction());
          dispatch(storeUiStatusAction({
            modalOpen: true,
            modalContentKey: DormerModalContentKey.COST_SUMMARY,
            modalData: {}
          }));
        }}
      >
        <div style={{display: 'flex', flexDirection: 'row', width: '300px', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', flexDirection: 'row'}} className={styles.text}>
            <Conditional
              condition={!hidePrice}
              fallback={<Text as={'h6'}>{t(TranslationKeys.generic.priceOnInquiry)}</Text>}
            >
              <Text as={'h6'}>{t(TranslationKeys.generic.indication, 'Prijsindicatie')}: </Text>
            </Conditional>
            <Conditional condition={!hidePrice}>
              <Text as={'h6'} style={{color: '#000000'}}>
                {invoice?.orderInvoiceViewModel?.priceText}&nbsp;
              </Text>
            </Conditional>
          </div>

          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Conditional condition={isLoading}>
              <Spinner size={24}/>
            </Conditional>
            <Conditional condition={!modalOpen}>
              <KeyboardArrowDown/>
            </Conditional>
            <Conditional condition={modalOpen}>
              <KeyboardArrowUp/>
            </Conditional>
          </div>
        </div>

      </div>
    </>
  );
};
