import {GetOrderInvoiceViewModel} from "@hec/api-dtos";
import {DormerRootState} from "../DormerRootState";
import {getOrderIsDirtySelector} from "../Order";

export const getOrderInvoiceSelector = (state: DormerRootState): GetOrderInvoiceViewModel | null => {
  return state.orderInvoice.orderInvoice;
}



export const getOrderInvoiceIsDirtySelector = (state: DormerRootState): boolean | null => {
  return state?.orderInvoice?.isDirty;
}
