import React, {FunctionComponent, useMemo} from "react";
import styles from './extras.module.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  Conditional,
  Dropdown,
  SelectableSectionFragmentColorSelector,
  SelectableSectionFragmentSelector,
  SidebarFooter
} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {
  DormerColorType,
  DormerRootState,
  getClientInformationSelector,
  getConfiguredSectionFragmentSelector,
  getSelectableFrameOverlaySelector,
  getSelectableRoofTrimSelector,
  UpdateSectionFragmentDormerColorActionProps,
  updateSectionFragmentDormerColorsAction,
  updateSelectedUniqueGroupSectionFragmentAction,
  updateSelectedRoofTrimAction
} from "@hec/dal";
import {ExtraInformationForKeyRenderer} from "../../components";
import {TranslationKeys} from "@hec/models";
import {FrameOverlaysDto, RoofTrimDto, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {useCustomTranslation} from "@hec/dal";

export const ExtrasSideBarContent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const {t} = useCustomTranslation();

  const nextRoutePath = RoutePaths.CustomerSurveyPath;
  const nextRouteButtonText = t(TranslationKeys.dormer.pages.customerSurveyPage.cta);
  const clientInformation = useSelector(getClientInformationSelector);
  const selectableRoofTrims = useSelector(getSelectableRoofTrimSelector);
  const orderRoofTrim: RoofTrimDto | undefined = (useSelector((state: DormerRootState) => getConfiguredSectionFragmentSelector(state, SectionFragmentType.RoofTrim)) as RoofTrimDto[]).find(x => x != null);

  const selectableFrameOverlays = useSelector(getSelectableFrameOverlaySelector);
  const orderFrameOverlays: FrameOverlaysDto[] | undefined = (useSelector((state: DormerRootState) => getConfiguredSectionFragmentSelector(state, SectionFragmentType.FrameOverlay)) as FrameOverlaysDto[]).filter(x => x != null);

  const selectedRoofTrim = useMemo(
    () => orderRoofTrim?.copiedFrom ?? selectableRoofTrims.find(x => x.isDefaultForTemplate),
    [orderRoofTrim?.copiedFrom, selectableRoofTrims]);


  interface FrameOverlayGroupsSelectionModel {
    sfDtos: SectionFragmentDto[];
    groupNumber: number;
  }

  const selectableFrameOverlayGroupsSelection: FrameOverlayGroupsSelectionModel[] = useMemo(() => {
    const groupedOverlays = new Map<number, FrameOverlayGroupsSelectionModel>();

    // Group non-default selectable frame overlays
    selectableFrameOverlays
      .filter(x => !x.isDefaultForTemplate && x.sectionFragmentType === SectionFragmentType.FrameOverlay)
      .forEach(overlay => {
        const groupKey = overlay.uniqueGroup ?? 0;
        const currentGroup = groupedOverlays.get(groupKey) || {
          sfDtos: [],
          groupNumber: groupKey,
          // colorforSfId: new Map<string, DormerColorViewModel>()
        };

        currentGroup.sfDtos.push(overlay);
        groupedOverlays.set(groupKey, currentGroup);
      });

    const result = Array.from(groupedOverlays.values());

    console.log('selectableFrameOverlayGroupsSelection', result);
    return result;
  }, [selectableFrameOverlays]);


  const selectedFrameOverlay = useMemo(
    () => orderFrameOverlays.find(x => x.sectionFragmentType === SectionFragmentType.FrameOverlay)?.copiedFrom ?? selectableFrameOverlays.find(x => x.isDefaultForTemplate && x.sectionFragmentType === SectionFragmentType.FrameOverlay),
    [orderFrameOverlays, selectableFrameOverlays]);

  const selectedFrameOverlays: SectionFragmentDto[] = useMemo(() => {
    const groupedOverlays = new Map<string, SectionFragmentDto>();

    // First, iterate through `orderFrameOverlays` and add them to the map based on their `uniqueGroup`
    orderFrameOverlays
      .filter(x => x.sectionFragmentType === SectionFragmentType.FrameOverlay)
      .forEach(overlay => {
        const groupKey = (overlay.uniqueGroup ?? 0).toString();
        if (!groupedOverlays.has(groupKey)) {
          groupedOverlays.set(groupKey, overlay.copiedFrom || overlay);
        }
      });

    // Defaults defined for group
    selectableFrameOverlays
      .filter(x => x.isDefaultForTemplate && x.sectionFragmentType === SectionFragmentType.FrameOverlay)
      .forEach(overlay => {
        const groupKey = (overlay.uniqueGroup ?? 0).toString();
        if (!groupedOverlays.has(groupKey)) {
          groupedOverlays.set(groupKey, overlay);
        }
      });

    // Return an array of the selected overlays from each group
    return Array.from(groupedOverlays.values());
  }, [orderFrameOverlays, selectableFrameOverlays]);

  if (clientInformation === null) {
    return <></>;
  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'ExtrasStepExtraInformation'}
        />

        <Conditional condition={selectableRoofTrims.length > 1}>
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <Dropdown title={t(TranslationKeys.dormer.pages.extrasPage.rooftrimDropdownTitle, 'Daktrim')}>
            <SelectableSectionFragmentSelector
              sectionFragments={selectableRoofTrims}
              selectedSectionFragmentId={selectedRoofTrim?.id ?? null}
              onSelectionChanged={(dto) => {
                dispatch(updateSelectedRoofTrimAction(dto.id!));
              }}/>
            <Conditional condition={selectedRoofTrim}>
              <SelectableSectionFragmentColorSelector
                colorId={orderRoofTrim?.configuredDormerColorId ?? selectedRoofTrim?.configuredDormerColorId ?? null}
                dormerMaterialViewModels={selectedRoofTrim?.availableDormerMaterials ?? []}
                onSelectionChanged={(id) => {
                  if (orderRoofTrim?.id && selectedRoofTrim) {
                    const color = selectedRoofTrim.availableDormerMaterials
                      .flatMap(x => x.dormerColors)
                      .find(x => x.id === id);
                    if (color) {
                      const change: UpdateSectionFragmentDormerColorActionProps = {
                        id: orderRoofTrim?.id,
                        color,
                      };

                      const action = updateSectionFragmentDormerColorsAction({changes: [change]});
                      dispatch(action);
                    }
                  }
                }}
                dropdownTitle={t(TranslationKeys.dormer.pages.extrasPage.rooftrimColorDropdownTitle, 'Daktrim uitvoering')}
              />
            </Conditional>
          </Dropdown>
        </Conditional>

        {selectableFrameOverlayGroupsSelection.map(fogsm => {
          const selected = selectedFrameOverlays.find(x => x.uniqueGroup === fogsm.groupNumber);
          const selectedId= selected?.id ?? null;

            const allMaterials = selected?.availableDormerMaterials ?? [];


            // Filter by unique based on 'id' (or any other unique property)
            const uniqueMaterials = allMaterials.filter((material, index, self) =>
              index === self.findIndex((m) => m.id === material.id)
            );

            const frameOverlayColors = uniqueMaterials.flatMap(x => x.dormerColors);

            const allMaterialsSideRods = selected?.availableDormerMaterialsSideRods ?? []

            // Filter by unique based on 'id' (or any other unique property)
            const uniqueMaterialsSideRods = allMaterialsSideRods.filter((material, index, self) =>
              index === self.findIndex((m) => m.id === material.id)
            );

            const frameOverlayColorsSideRod = uniqueMaterialsSideRods.flatMap(x => x.dormerColors);


          return <Dropdown
            title={t(`${TranslationKeys.dormer.pages.extrasPage.uniqueGroupTitlePrefix}.${fogsm.groupNumber}`)}>
            <SelectableSectionFragmentSelector
              sectionFragments={fogsm.sfDtos}
              allowUnset={fogsm.groupNumber !== 4}
              selectedSectionFragmentId={selectedId}
              onSelectionChanged={(dto) => {
                dispatch(updateSelectedUniqueGroupSectionFragmentAction({
                  id: dto?.id ?? selectedId!,
                  deselect: dto?.id == null
                }));
              }}/>
            <Conditional
              condition={selected && (frameOverlayColors.length + frameOverlayColorsSideRod.length) > 0}>
              <SelectableSectionFragmentColorSelector
                colorId={orderFrameOverlays.find(x => x)?.configuredDormerColorId ?? selected?.configuredDormerColorId ?? null}
                dormerMaterialViewModels={selected?.availableDormerMaterials ?? []}
                onSelectionChanged={(id) => {
                  if (selected) {
                    const color = frameOverlayColors
                      .find(x => x.id === id);
                    if (color) {

                      const changes: UpdateSectionFragmentDormerColorActionProps[] = orderFrameOverlays?.map(x => {
                        return {
                          id: x?.id,
                          color,
                          dormerColorType: DormerColorType.normal
                        } as UpdateSectionFragmentDormerColorActionProps;
                      });


                      const action = updateSectionFragmentDormerColorsAction({changes});
                      dispatch(action);
                    }
                  }
                }}
                dropdownTitle={t(`${t(selected?.name ?? 'Sun screen', selected?.name ?? 'Zonnewering')} ${t('primary color', 'hoofd kleur')}`)}
              />
            </Conditional>

            <Conditional condition={(frameOverlayColorsSideRod.length) > 0}>
              <SelectableSectionFragmentColorSelector
                colorId={orderRoofTrim?.configuredDormerColorSideRodId ?? selected?.configuredDormerColorSideRodId ?? null}
                dormerMaterialViewModels={selected?.availableDormerMaterialsSideRods ?? []}
                onSelectionChanged={(id) => {
                  if (selected) {
                    const color = frameOverlayColorsSideRod
                      .find(x => x.id === id);
                    if (color) {

                      const changes: UpdateSectionFragmentDormerColorActionProps[] = orderFrameOverlays?.map(x => {
                        return {
                          id: x?.id,
                          color,
                          dormerColorType: DormerColorType.sideRods
                        } as UpdateSectionFragmentDormerColorActionProps;
                      });


                      const action = updateSectionFragmentDormerColorsAction({changes});
                      dispatch(action);
                    }
                  }
                }}
                dropdownTitle={t(`${t(selected?.name ?? 'Sun screen', selected?.name ?? 'Zonnewering')} ${t('secondaire color', 'secondaire kleur')}`)}
              />
            </Conditional>

          </Dropdown>

        })}
      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.MaterialPath}
        nextRoutePath={nextRoutePath}
        nextRouteCTA={nextRouteButtonText}
        clientInformation={clientInformation}
      />
    </>
  );
};
