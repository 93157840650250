import {Base3dProps} from "../Base3dProps";
import {
  CheeksDto,
  FaceDto, FrameOverlayAssemblyType,
  FrameOverlaysDto,
  FrameOverlaysType,
  SectionFragmentDto,
  SectionFragmentSide,
  SectionFragmentType, SplinterDto
} from "@hec/api-dtos";
import React, {FunctionComponent, useMemo} from "react";
import {
  fromMmToRenderingMeters, getSplinterHeight,
  getSplinterHeightFromSectionFragments,
  getSplinterFullHorizontalWidth, getThickestSplinterSide,
  getTotalWidth
} from "@hec/core";
import * as THREE from 'three';
import {MeshStandardMaterial} from 'three';
import {getSectionFragmentsFromFaceByType} from "@hec/dal";
import {
  distanceFromZeroZ, FrameOverlayRollerBlindsRenderer,
  FrameOverlayRollerShuttersRenderer,
  FrameOverlayRollerShuttersRoofWindowRenderer
} from "./FrameOverlayTypeRenderers";
import {
  FrameOverlayRollerBlindsRoofWindowRenderer
} from "./FrameOverlayTypeRenderers/FrameOverlayRollerBlindsRoofWindowRenderer";

export interface FrameOverlayRendererProps extends Base3dProps {
  face: FaceDto;
}

export const FrameOverlayRenderer: FunctionComponent<FrameOverlayRendererProps> =
  ({face, visualizationSettings}: FrameOverlayRendererProps) => {

    const sectionFragments = face?.sectionFragments ?? [];
    const totalWidth = getTotalWidth(face);
    const cheeks: CheeksDto[] = getSectionFragmentsFromFaceByType(face, SectionFragmentType.Cheeks);
    const cheek: CheeksDto | undefined = cheeks.find(x => x.cheekSide === SectionFragmentSide.South);
    const yOffSetFrames = fromMmToRenderingMeters(getThickestSplinterSide(face));

    // const splinterWidth = getSplinterFullHorizontalWidth(face);
    // const splinterHeight = getSplinterHeight(face)

    const xOffsetWestCheek = -fromMmToRenderingMeters(
      (totalWidth) / 2- (
        (cheek?.width ?? 0)
        // + (splinter?.width ?? 0)
      )
    );

    const frameOverlays: FrameOverlaysDto[] = getSectionFragmentsFromFaceByType(face, SectionFragmentType.FrameOverlay);

    // frameOverlays = example as any as FrameOverlaysDto[];


    return <group key={'frameoverlays'} position={[xOffsetWestCheek,0,0]}>
      {/*<mesh position={[.5,0,0]}>*/}
      {/*  <boxGeometry args={[1,1,1]}/>*/}
      {/*</mesh>*/}
      {frameOverlays.filter(x=> !!x.id).map((frameOverlay, index) => {
        if (frameOverlay.frameOverlaysType === FrameOverlaysType.RollerShutters) {
          if(frameOverlay.frameOverlayAssemblyType === FrameOverlayAssemblyType.OverSingularFrame) {
            return <group>
              <FrameOverlayRollerShuttersRoofWindowRenderer
                startCoordinateX={frameOverlay.startCoordinateX ?? 0}
                endCoordinateX={frameOverlay.endCoordinateX ?? 0}
                width={frameOverlay.width}
                // TODO: CHange to framoverlay.height
                totalHeight={frameOverlay.height}
                yOffSetFrames={yOffSetFrames}
                primaryColor={frameOverlay?.configuredDormerColor}
                secondaryColor={frameOverlay?.configuredDormerColorSideRods ?? frameOverlay?.configuredDormerColor}
              />
            </group>
          } else {
            return <group>
              <FrameOverlayRollerShuttersRenderer
                startCoordinateX={frameOverlay.startCoordinateX ?? 0}
                endCoordinateX={frameOverlay.endCoordinateX ?? 0}
                width={frameOverlay.width}
                // TODO: CHange to framoverlay.height
                totalHeight={frameOverlay.height}
                yOffSetFrames={yOffSetFrames}
                primaryColor={frameOverlay?.configuredDormerColor}
                secondaryColor={frameOverlay?.configuredDormerColorSideRods ?? frameOverlay?.configuredDormerColor}
              />
            </group>
          }
        } else if (frameOverlay.frameOverlaysType === FrameOverlaysType.RollerBlinds) {
          if(frameOverlay.frameOverlayAssemblyType === FrameOverlayAssemblyType.OverSingularFrame) {
            return <FrameOverlayRollerBlindsRoofWindowRenderer
              startCoordinateX={frameOverlay.startCoordinateX ?? 0}
              endCoordinateX={frameOverlay.endCoordinateX ?? 0}
              width={frameOverlay.width}
              // TODO: CHange to framoverlay.height
              totalHeight={frameOverlay.height}
              yOffSetFrames={yOffSetFrames}
              primaryColor={frameOverlay?.configuredDormerColor}
              secondaryColor={frameOverlay?.configuredDormerColorSideRods ?? frameOverlay?.configuredDormerColor}
            />
          } else {
            return <group>
              <FrameOverlayRollerBlindsRenderer
                startCoordinateX={frameOverlay.startCoordinateX ?? 0}
                endCoordinateX={frameOverlay.endCoordinateX ?? 0}
                width={frameOverlay.width}
                // TODO: CHange to framoverlay.height
                totalHeight={frameOverlay.height}
                yOffSetFrames={yOffSetFrames}
                primaryColor={frameOverlay?.configuredDormerColor}
                secondaryColor={frameOverlay?.configuredDormerColorSideRods ?? frameOverlay?.configuredDormerColor}
              />
            </group>
          }
        }

        return <group></group>
      })}
    </group>
  }


const example = [
  {
    "id": "2abf6b1d-30eb-4cde-b893-f1c48c23daa6",
    "tempId": null,
    "faceId": "c88db2c4-a473-4ecb-8205-ada04c4f1b28",
    "copiedFromId": "624c7404-8db8-ec9b-fb9b-635bb1cfb1f7",
    "copiedFrom": {
      "id": "624c7404-8db8-ec9b-fb9b-635bb1cfb1f7",
      "tempId": null,
      "faceId": null,
      "copiedFromId": null,
      "copiedFrom": null,
      "sort": 0,
      "width": 0,
      "height": 0,
      "availableDormerMaterialIds": [],
      "availableDormerMaterials": [],
      "configuredDormerColorId": null,
      "configuredDormerColor": null,
      "sectionFragmentType": 2,
      "priceCalculationStrategyId": null,
      "priceCalculationStrategy": null,
      "isDefaultForTemplate": false,
      "minWidth": null,
      "maxWidth": null,
      "name": "Screen",
      "minHeight": null,
      "maxHeight": null,
      "variation": null,
      "supportedSizes": null,
      "cheekSide": null,
      "frameOverlaysType": 1,
      "startCoordinateX": null,
      "endCoordinateX": null,
      "availableDormerMaterialsSideRods": [],
      "availableDormerMaterialsSideRodIds": null,
      "configuredDormerColorSideRods": null,
      "configuredDormerColorSideRodId": null,
      "frameOverlayAssemblyType": 1,
      "roofTrimVariation": 0,
      "splinterSide": 0,
      "stubbedSide": 0,
      "organisationId": "c75fabe7-9fe2-45f6-a926-4cd0b8d4668f",
      "orderId": null,
      "configurationTemplateId": "0b06d74e-f497-4b51-af02-d4a058ed0e13",
      "faceConfigurationId": "3098b60e-6fb6-4ccc-af70-39f3b5f91789",
      "isOrganisationAddable": false,
      "isUserSelectable": true,
      "belongsToUser": false
    },
    "sort": 11,
    "width": 660,
    "height": 1544,
    "availableDormerMaterialIds": [],
    "availableDormerMaterials": [],
    "configuredDormerColorId": null,
    "configuredDormerColor": null,
    "sectionFragmentType": 2,
    "priceCalculationStrategyId": null,
    "priceCalculationStrategy": null,
    "isDefaultForTemplate": false,
    "minWidth": null,
    "maxWidth": null,
    "name": "Screen",
    "minHeight": null,
    "maxHeight": null,
    "variation": null,
    "supportedSizes": null,
    "cheekSide": null,
    "frameOverlaysType": 1,
    "startCoordinateX": 73,
    "endCoordinateX": 733,
    "availableDormerMaterialsSideRods": [],
    "availableDormerMaterialsSideRodIds": null,
    "configuredDormerColorSideRods": null,
    "configuredDormerColorSideRodId": null,
    "frameOverlayAssemblyType": 1,
    "roofTrimVariation": 0,
    "splinterSide": 0,
    "stubbedSide": 0,
    "organisationId": null,
    "orderId": "8778683e-6707-4160-ae2a-3653b50a0e2e",
    "configurationTemplateId": null,
    "faceConfigurationId": "16c315c1-7255-4326-91b4-fc0f21adca31",
    "isOrganisationAddable": false,
    "isUserSelectable": false,
    "belongsToUser": true
  }
]
