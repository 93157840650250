import { Canvas } from '@react-three/fiber';
import { CSSProperties, FunctionComponent, useMemo } from 'react';
import { HomeExtension } from './HomeExtension';
import { DEFAULT_ZOOM } from './Camera';
import { HomeExtensionDto } from './HomeExtensionDto';
import { SoftShadows, useDetectGPU } from '@react-three/drei';
import {Conditional} from "../Conditional";
export interface RendererProps {
  lowPerformanceMode: boolean;
  homeExtensionDto: HomeExtensionDto;
  style?: CSSProperties;
  zoom?: number;
  className?: string;
  animate?: boolean;
  isInitialPositionCallback?: () => void;
}

export const InnerRenderer: FunctionComponent<RendererProps> = ({
  isInitialPositionCallback,
  lowPerformanceMode,
  zoom = DEFAULT_ZOOM,
  homeExtensionDto,
  className,
  style,
  animate = true,
}) => {
  const useShadows = useMemo(() => !lowPerformanceMode, []);
  const detectedGpu = useDetectGPU();

  return (
    <div style={style} className={className}>
      <Canvas shadows={useShadows} gl={{ localClippingEnabled: true }}>
        {/*<Conditional condition={detectedGpu.tier === 3}>*/}
        {/*  <SoftShadows/>*/}
        {/*</Conditional>*/}
        <HomeExtension
          zoom={zoom}
          homeExtensionDto={homeExtensionDto}
          animate={animate}
          isInitialPositionCallback={isInitialPositionCallback}
        />
      </Canvas>
    </div>
  );
};
