import { FunctionComponent } from 'react';
import {
  TextureLoader,
  MeshLambertMaterial,
  Texture,
  RepeatWrapping,
} from 'three';
import { useSelector } from 'react-redux';
import { HomeExtensionRootState } from '@hec/core';
import { useTexture } from '@react-three/drei';
import {
  GARDEN_TILING_DEPTH,
  GRASS_DEPTH,
  GroundDimensions,
  MINIMUM_GARDEN_WIDTH,
} from './CalculateGroundDimensions';

const outsideTilingCdnUrl =
  'https://cdn.aanbouwconfigurator.com/static-assets/generic/garden/grass.jpg';

interface GrassProps {
  groundDimensions: GroundDimensions;
}

export const Grass: FunctionComponent<GrassProps> = ({
  groundDimensions,
}: GrassProps) => {
  const yPositionOfTiling = -0.001;

  const { grassWidth, grassDepth, xOffsetGrass, zOffsetGrass } =
    groundDimensions;

  const map = useTexture(outsideTilingCdnUrl);

  const repeatPerMeter = 0.6;
  map.wrapS = RepeatWrapping;
  map.wrapT = RepeatWrapping;
  map.repeat.set(grassWidth * repeatPerMeter, grassDepth * repeatPerMeter);

  return (
    <group
      position={[
        xOffsetGrass + grassWidth / 2,
        yPositionOfTiling,
        zOffsetGrass + grassDepth / 2,
      ]}
    >
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry
          attach="geometry"
          args={[grassWidth, grassDepth]}
        />
        <meshBasicMaterial map={map} transparent={false} depthWrite={true} />
      </mesh>
    </group>
  );
};
