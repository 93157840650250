import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  CheeksDto,
  DormerMaterialViewModel,
  FrameVariation,
  ModifyGroupIndex,
  SectionFragmentDto,
  SectionFragmentSide,
  SectionFragmentType,
  StubbedDto
} from '@hec/api-dtos';
import { OptionList } from '../OptionList';
import { getClientInformationSelector, useCustomTranslation } from '@hec/dal';
import { useSelector } from 'react-redux';
import { Dropdown } from '../Dropdown';
import { Button } from '../Button';

export interface SelectionForSectionFragment {
  [key: string]: string | null;
}

export interface MaterialAndColorSelectionDropDownProps {
  sectionFragments: SectionFragmentDto[];
  selectedColorChanged: (colorSelection: SelectionForSectionFragment) => void;
  deGroupableModifyGroups: ModifyGroupIndex[];
  deGroupableTypes: SectionFragmentType[];
}

interface GroupedSectionFragment {
  sectionFragmentIds: string[];
  sectionFragments: SectionFragmentDto[];
  materialOptions: MaterialOption[];
  title: string;
  canDegroupModifyGroup: boolean;
  canDegroupSectionFragmentType: boolean;
  onDegroupModifyGroup?: () => void;
  onDegroupSectionFragmentType?: () => void;
  modifyGroupButtonLabel?: string;
  sectionFragmentTypeButtonLabel?: string;
}

interface MaterialOption {
  displayName: string;
  materialName: string;
  materialId: string;
  colors: MaterialOptionColor[];
}

interface MaterialOptionColor {
  colorId: string;
  colorName: string;
  displayName: string;
  imageUrl: string;
  colorCode: string;
  isSelected: boolean;
}

interface FragmentData {
  fragment: SectionFragmentDto;
  fragmentId: string;
  modifyGroup: ModifyGroupIndex;
  materialColorKey: string;
  sectionFragmentType: SectionFragmentType;
}

export const MaterialAndColorSelectionDropDown: FunctionComponent<MaterialAndColorSelectionDropDownProps> = ({
                                                                                                               sectionFragments,
                                                                                                               selectedColorChanged,
                                                                                                               deGroupableModifyGroups,
                                                                                                               deGroupableTypes,
                                                                                                             }) => {
  const [deGroupedModifyGroups, setDeGroupedModifyGroups] = useState<ModifyGroupIndex[]>([]);
  const [deGroupedTypes, setDeGroupedTypes] = useState<SectionFragmentType[]>([]);
  const clientInformation = useSelector(getClientInformationSelector);
  const { t } = useCustomTranslation();

  const [selectedColors, setSelectedColors] = useState<SelectionForSectionFragment>({});

  const groups = groupSectionFragments(
    sectionFragments,
    selectedColors,
    deGroupedModifyGroups,
    deGroupedTypes,
    deGroupableModifyGroups,
    deGroupableTypes,
    setDeGroupedModifyGroups,
    setDeGroupedTypes,
    t
  );

const setSelectedColorStateForSectionFragmentIds = (
  sectionFragmentIds: string[],
  selectedColorId: string
) => {
  setSelectedColors((prevSelectedColors) => {
    const updatedColors: SelectionForSectionFragment = { ...prevSelectedColors };

    let selectedColorName: string | undefined = undefined;
    let selectedMaterialName: string | undefined = undefined;

    const firstFragment = sectionFragments.find(
      (f) => (f.id ?? f.tempId ?? '') === sectionFragmentIds[0]
    );
    if (firstFragment) {
      const materials = getAvailableDormerMaterials(firstFragment);
      outerLoop: for (const material of materials) {
        for (const color of material.dormerColors) {
          if (color.id === selectedColorId) {
            selectedColorName = color.name;
            selectedMaterialName = material.name;
            break outerLoop;
          }
        }
      }
    }

    if (selectedColorName && selectedMaterialName) {
      sectionFragmentIds.forEach((sfId) => {
        const fragment = sectionFragments.find((f) => (f.id ?? f.tempId ?? '') === sfId);
        if (fragment) {
          const materials = getAvailableDormerMaterials(fragment);
          const material = materials.find((m) => m.name === selectedMaterialName);
          if (material) {
            const color = material.dormerColors.find((c) => c.name === selectedColorName);
            if (color) {
              updatedColors[sfId] = color.id!;
            }
          }
        }
      });
    }

    return updatedColors;
  });
};

  useEffect(() => {
    selectedColorChanged(selectedColors);
  }, [selectedColors, selectedColorChanged]);

  if (groups.length === 0) {
    return null;
  }

  return (
    <>
      {groups.map((group, index) => {
        return (
          <Dropdown key={`dd-group-${index}`} title={group.title}>
            <div style={{ paddingBottom: '10px' }}>
              {group.canDegroupModifyGroup && group.onDegroupModifyGroup && (
                <Button
                  size={'small'}
                  style={{ backgroundColor: '#f1f1f1', color: 'black' }}
                  as={<div />}
                  onClick={group.onDegroupModifyGroup}
                >
                  {group.modifyGroupButtonLabel}
                </Button>
              )}
              {group.canDegroupSectionFragmentType && group.onDegroupSectionFragmentType && (
                <Button
                  size={'small'}
                  style={{ backgroundColor: '#f1f1f1', color: 'black' }}
                  as={<div />}
                  onClick={group.onDegroupSectionFragmentType}
                >
                  {group.sectionFragmentTypeButtonLabel}
                </Button>
              )}
            </div>
            <OptionList
              items={group.materialOptions.flatMap((material) =>
                material.colors.map((color) => ({
                  id: color.colorId,
                  name: color.displayName,
                  imageSvg: color.imageUrl ? (
                    <img
                      height={30}
                      width={30}
                      src={color.imageUrl}
                      alt={`${color.displayName} ${color.colorCode}`}
                      style={{ backgroundColor: `#${color.colorCode}` }}
                    />
                  ) : (
                    <div style={{ width: '30px', height: '30px', backgroundColor: `#${color.colorCode}` }} />
                  ),
                  subTitle: material.displayName,
                }))
              )}
              selectedItem={selectedColors[group.sectionFragmentIds[0]]}
              setSelectedItem={(selectedColorId) => {
                setSelectedColorStateForSectionFragmentIds(group.sectionFragmentIds, selectedColorId!);
              }}
              primaryColor={clientInformation?.primaryColor}
            />
          </Dropdown>
        );
      })}
    </>
  );
};

function groupSectionFragments(
  sectionFragments: SectionFragmentDto[],
  selectedColors: SelectionForSectionFragment,
  deGroupedModifyGroups: ModifyGroupIndex[],
  deGroupedTypes: SectionFragmentType[],
  deGroupableModifyGroups: ModifyGroupIndex[],
  deGroupableTypes: SectionFragmentType[],
  setDeGroupedModifyGroups: React.Dispatch<React.SetStateAction<ModifyGroupIndex[]>>,
  setDeGroupedTypes: React.Dispatch<React.SetStateAction<SectionFragmentType[]>>,
  t: (key: string) => string
): GroupedSectionFragment[] {
  const groups: GroupedSectionFragment[] = [];

  const fragmentData = sectionFragments.map((fragment) => {
    const modifyGroup = fragment.modifyGroup ?? ModifyGroupIndex.None;
    const { materialName, colorName } = getSelectedMaterialAndColor(fragment, selectedColors);
    const materialColorKey = `${materialName}-${colorName}`;
    const sectionFragmentType = fragment.sectionFragmentType;
    const fragmentId = fragment.id ?? fragment.tempId ?? '';

    return {
      fragment,
      fragmentId,
      modifyGroup,
      materialColorKey,
      sectionFragmentType,
    };
  });

  const modifyGroupMap = new Map<string, typeof fragmentData>();

  fragmentData.forEach((data) => {
    const isModifyGroupDegrouped = deGroupedModifyGroups.includes(data.modifyGroup);
    const isTypeDegrouped = deGroupedTypes.includes(data.sectionFragmentType);

    let groupKey = data.materialColorKey;

    if (data.sectionFragmentType === SectionFragmentType.Frame && data.fragment.variation === FrameVariation.Window) {
      return;
    }

    const dormerColors = data.fragment.copiedFrom?.availableDormerMaterials
      .flatMap(x => x.dormerColors);

    if (!dormerColors || dormerColors.length <= 1) {
      return;
    }

    if (data.modifyGroup !== ModifyGroupIndex.None) {
      groupKey = `mg-${ModifyGroupIndex[data.modifyGroup]}-${groupKey}`;
    }

    if ((isModifyGroupDegrouped || data.modifyGroup === ModifyGroupIndex.None)) {
      groupKey = `sft-${SectionFragmentType[data.sectionFragmentType]}-${groupKey}`;
    }

    if ((isModifyGroupDegrouped || data.modifyGroup === ModifyGroupIndex.None) && isTypeDegrouped) {
      groupKey = `id-${data.fragmentId}-${groupKey}`;
    }

    console.log(groupKey);

    if (!modifyGroupMap.has(groupKey)) {
      modifyGroupMap.set(groupKey, []);
    }
    modifyGroupMap.get(groupKey)!.push(data);
  });

  modifyGroupMap.forEach((fragmentsInGroup, groupKey) => {
    const sampleData = fragmentsInGroup[0];
    const modifyGroup = sampleData.modifyGroup;
    const sectionFragmentTypes = [...new Set(fragmentsInGroup.map((data) => data.sectionFragmentType))];
    const isModifyGroupDegrouped = deGroupedModifyGroups.includes(modifyGroup);
    const isTypeDegrouped = sectionFragmentTypes.every((type) => deGroupedTypes.includes(type));

    const canDegroupModifyGroup =
      deGroupableModifyGroups.includes(modifyGroup);

    const canDegroupSectionFragmentType = !isModifyGroupDegrouped && modifyGroup !== ModifyGroupIndex.None
      ? false
      : deGroupableTypes.some((type) => sectionFragmentTypes.includes(type));

    const dropdownTitle = getDropdownTitle(canDegroupModifyGroup, isModifyGroupDegrouped, modifyGroup, fragmentsInGroup, t)

    const sectionFragmentIds = fragmentsInGroup.map((data) => data.fragmentId);
    const sectionFragments = fragmentsInGroup.map((data) => data.fragment);

    const group: GroupedSectionFragment = {
      sectionFragmentIds,
      sectionFragments,
      materialOptions: getMaterialOptions(sectionFragments, selectedColors),
      title: dropdownTitle,
      canDegroupModifyGroup,
      canDegroupSectionFragmentType,
    };

    if (canDegroupModifyGroup) {
      group.onDegroupModifyGroup = () => {
        setDeGroupedModifyGroups((prev: ModifyGroupIndex[]) => {
          if (prev.includes(modifyGroup)) {
            return prev.filter((mg) => mg !== modifyGroup);
          } else {
            return [...prev, modifyGroup];
          }
        });
      };
      group.modifyGroupButtonLabel = getGroupButtonLabel(dropdownTitle, isModifyGroupDegrouped, t);
    }

    if (canDegroupSectionFragmentType) {
      group.onDegroupSectionFragmentType = () => {
        const typesToToggle = sectionFragmentTypes.filter((type) => deGroupableTypes.includes(type));

        setDeGroupedTypes((prev: SectionFragmentType[]) => {
          let updated = [...prev];
          typesToToggle.forEach((type) => {
            if (updated.includes(type)) {
              updated = updated.filter((t) => t !== type);
            } else {
              updated.push(type);
            }
          });
          return updated;
        });
      };
      group.sectionFragmentTypeButtonLabel = getGroupButtonLabel(dropdownTitle, isTypeDegrouped, t);
    }

    groups.push(group);
  });

  return groups;
}

function getMaterialOptions(
  sectionFragments: SectionFragmentDto[],
  selectedColors: SelectionForSectionFragment
): MaterialOption[] {
  const allMaterials = sectionFragments.flatMap((fragment) => getAvailableDormerMaterials(fragment));
  const uniqueMaterials = Array.from(new Map(allMaterials.map((m) => [m.name, m])).values());

  return uniqueMaterials.map((material) => {
    const allColors = sectionFragments
      .flatMap((fragment) =>
        getAvailableDormerMaterials(fragment)
          .filter((m) => m.name === material.name)
          .flatMap((m) => m.dormerColors)
      )
      .filter((color, index, self) => self.findIndex((c) => c.name === color.name) === index); // Unique colors by name

    const colors = allColors.map((color) => ({
      colorId: color.id!,
      colorName: color.name,
      displayName: color.name,
      imageUrl: color.textureUrl,
      colorCode: color.colorCode,
      isSelected: sectionFragments.every(
        (fragment) =>
          getSelectedMaterialAndColor(fragment, selectedColors).colorName === color.name &&
          getSelectedMaterialAndColor(fragment, selectedColors).materialName === material.name
      ),
    }));

    return {
      materialId: material.id!,
      materialName: material.name,
      displayName: material.name,
      colors,
    };
  });
}

function getSelectedMaterialAndColor(
  fragment: SectionFragmentDto,
  selectedColors: SelectionForSectionFragment
): { materialName: string; colorName: string } {
  const selectedColorId = selectedColors[fragment.id ?? fragment.tempId ?? ''] ?? fragment.configuredDormerColorId;
  const materials = getAvailableDormerMaterials(fragment);

  for (const material of materials) {
    for (const color of material.dormerColors) {
      if (color.id === selectedColorId) {
        return { materialName: material.name, colorName: color.name };
      }
    }
  }

  for (const material of materials) {
    for (const color of material.dormerColors) {
      if (color.name === fragment.configuredDormerColor?.name) {
        return { materialName: material.name, colorName: color.name };
      }
    }
  }

  if (materials.length > 0 && materials[0].dormerColors.length > 0) {
    return { materialName: materials[0].name, colorName: materials[0].dormerColors[0].name };
  }

  return { materialName: '', colorName: '' };
}

const getAvailableDormerMaterials = (sectionFragment: SectionFragmentDto): DormerMaterialViewModel[] => {
  const result =
    (sectionFragment.copiedFrom?.availableDormerMaterials?.length ?? 0) >=
    (sectionFragment.availableDormerMaterials?.length ?? 0)
      ? sectionFragment.copiedFrom?.availableDormerMaterials
      : sectionFragment.availableDormerMaterials;
  return result ?? [];
};

function getDropdownTitle(
  canDegroupModifyGroup: boolean,
  isModifyGroupDegrouped: boolean,
  modifyGroup: ModifyGroupIndex,
  fragmentsInGroup: FragmentData[],
  t: (key: string) => string
): string {
  const fragmentNames = [...new Set(
    fragmentsInGroup.map((data) => {
      if (data.fragment.name === '' || data.fragment.name === null) {
        switch (data.sectionFragmentType) {
          case SectionFragmentType.Cheeks:
            return t(SectionFragmentType[data.sectionFragmentType] + '-' + SectionFragmentSide[(data.fragment as CheeksDto).cheekSide as unknown as keyof typeof SectionFragmentSide])
          case SectionFragmentType.Stubbed:
            return t(SectionFragmentType[data.sectionFragmentType] + '-' + SectionFragmentSide[(data.fragment as StubbedDto).stubbedSide as unknown as keyof typeof SectionFragmentSide])
          default:
            return t(SectionFragmentType[data.sectionFragmentType])
        }
      } else {
        return data.fragment.name
      }
    })
  )];

  if (!canDegroupModifyGroup && !isModifyGroupDegrouped && modifyGroup !== ModifyGroupIndex.None) {
    return t(ModifyGroupIndex[modifyGroup]);
  } else {
    if (fragmentNames.length > 1) {
      return fragmentNames.slice(0, -1).join(', ') + ' ' + t('and') + ' ' + fragmentNames.at(-1);
    } else {
      return fragmentNames[0];
    }
  }
}

function getGroupButtonLabel(dropdownTitle: string, isDegrouped: boolean, t: (key: string) => string): string {
  if (isDegrouped) {
    return `${t('Regroup')} ${dropdownTitle}`;
  } else {
    return `${t('Degroup')} ${dropdownTitle}`;
  }
}
