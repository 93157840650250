import { createAction } from '@reduxjs/toolkit';
import {GetOrderInvoiceViewModel} from "@hec/api-dtos";

export interface GetOrderInvoiceSucceededActionProps {
  orderInvoice: GetOrderInvoiceViewModel;
}

export const getOrderInvoiceAction = createAction('getOrderInvoicesAction');
export const markDirtyOrderInvoiceAction = createAction('markDirtyOrderInvoiceAction');

export const getOrderInvoiceSucceededAction = createAction<GetOrderInvoiceSucceededActionProps>('getOrderInvoicesSucceededAction');
