import { FunctionComponent } from 'react';
import {
  TextureLoader,
  MeshLambertMaterial,
  Texture,
  RepeatWrapping,
} from 'three';
import { useSelector } from 'react-redux';
import { HomeExtensionRootState } from '@hec/core';
import { useTexture } from '@react-three/drei';
import {
  GroundDimensions,
  MINIMUM_GARDEN_WIDTH,
} from './CalculateGroundDimensions';

const outsideTilingCdnUrl =
  'https://cdn.aanbouwconfigurator.com/static-assets/generic/garden/tiles.jpg';

interface GardenTilingProps {
  groundDimensions: GroundDimensions;
}

export const GardenTiling: FunctionComponent<GardenTilingProps> = ({
  groundDimensions,
}: GardenTilingProps) => {
  const yPositionOfTiling = -0.001;
  const { depth, width, tileWidth, tileDepth, zOffsetTile, xOffsetTile } =
    groundDimensions;

  const map = useTexture(outsideTilingCdnUrl);

  const repeatPerMeter = 0.5;
  map.wrapS = RepeatWrapping;
  map.wrapT = RepeatWrapping;
  map.repeat.set(tileWidth * repeatPerMeter, tileDepth * repeatPerMeter);

  return (
    <group
      position={[
        xOffsetTile + tileWidth / 2,
        yPositionOfTiling,
        zOffsetTile + tileDepth / 2,
      ]}
    >
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry attach="geometry" args={[tileWidth, tileDepth]} />
        <meshBasicMaterial map={map} transparent={false} depthWrite={true} />
      </mesh>
    </group>
  );
};
