import {GetOrderInvoiceViewModel} from "@hec/api-dtos";

export interface OrderInvoiceState {
  orderInvoice: GetOrderInvoiceViewModel | null;
  isDirty: boolean;
}

export const orderInvoiceInitialState: OrderInvoiceState = {
  orderInvoice: null,
  isDirty: false,
}
