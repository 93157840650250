import {
  debouncePutOrderAction,
  getOrderAction,
  getOrderSelector,
  getOrderSucceededAction,
  OrderState,
  PostOrderService,
  putCustomerInformationAction,
  PutCustomerInformationService,
  putOrderAction,
  putOrderSucceededAction,
  updateMeasurementsAction
} from './index';
import {call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {
  DormerRootState,
  getApplicationService,
  getClientInformationSelector,
  getIsOrganisationSelector,
  getOrderInvoiceAction,
  getSelectableSectionFragmentsAction,
  getSelectedConfigurationTemplateSelector, markDirtyOrderInvoiceAction,
  storeErrorAction,
} from "../../";
import {ClientInformationViewModel, ConfigurationTemplateDto, OrderDto, Side} from "@hec/api-dtos";
import {PutOrderResult, PutOrderService} from "./Services/PutOrderService";
import {throttle} from "@redux-saga/core/effects";
import {TranslationKeys} from "@hec/models";
import {toast} from "react-toastify";
import i18next from "i18next";

function* handleGetOrder() {
  const service: PostOrderService = yield getApplicationService(PostOrderService);
  const selectedConfigurationTemplate: ConfigurationTemplateDto = yield select(getSelectedConfigurationTemplateSelector);

  try {
    if (!selectedConfigurationTemplate) {
      yield put(storeErrorAction({
        error: new Error('No configuration template selected')
      }));
      return;
    }

    const result: OrderDto = yield call([service, 'cloneOrder'], selectedConfigurationTemplate.id);
    yield put(getOrderSucceededAction({ order: result}));
    yield put(updateMeasurementsAction({width: selectedConfigurationTemplate.defaultWidth, height: selectedConfigurationTemplate.defaultHeight}));
    yield put(getSelectableSectionFragmentsAction());

    // Materials are already mapped on their selectable frames
    // yield put(getDormerMaterialsAction());
  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}

function* handlePutOrder() {
  const service: PutOrderService = yield getApplicationService(PutOrderService);
  const order: OrderDto = yield select(getOrderSelector);

  try {
    if (!order) {
      yield put(storeErrorAction({
        error: new Error('No order found')
      }));
      return;
    }

    const result: PutOrderResult = yield call([service, 'putOrder'], order, order.configurationCode);


    const payloadSectionFragments = result.order.faceConfiguration.faces[0].sectionFragments!;

    const newSectionFragmentIds = payloadSectionFragments.filter(x=> x.id != null).map(x=> x.id);
    const duplicatIdSectionFragments = payloadSectionFragments.filter(x=> newSectionFragmentIds.filter(id => x.id == id).length > 1);

    if(duplicatIdSectionFragments.length > 0) {
      console.error("DUPLICATE ID REPLACEMENT ERR!");
      console.log(JSON.stringify(duplicatIdSectionFragments))
      console.error("DUPLICATE ID REPLACEMENT ERR!");
    }

    if(result) {
      yield put(putOrderSucceededAction({ orderDto: result.order}));

      if(result.modified) {
        yield put(markDirtyOrderInvoiceAction());
        yield put(getOrderInvoiceAction());
      }
    }
  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}

function notifySuccess() {
  return new Promise<void>(() => {
    toast.success(i18next.t(TranslationKeys.notifications.quotationEmailSent) as string);
  });
}

function* handlePutCustomerInformation(action: ReturnType<typeof putCustomerInformationAction>) {
  const service: PutCustomerInformationService = yield getApplicationService(PutCustomerInformationService);
  const order: OrderDto = yield select(getOrderSelector);

  try {
    if (!order) {
      yield put(storeErrorAction({
        error: new Error('No order found')
      }));
      return;
    }

    yield call([service, 'putCustomerInformation'], action.payload, order.configurationCode);

    const clientInformation: ClientInformationViewModel | null = yield select(getClientInformationSelector);
    const isOrganisation: boolean = yield select(getIsOrganisationSelector);

    const useLeadPostedSuccessRedirectUrl = !!clientInformation?.leadPostedSuccessRedirectUrl && isOrganisation;

    const redirectUrl = clientInformation?.leadPostedSuccessRedirectUrl;
    if( useLeadPostedSuccessRedirectUrl) {
      window.location.href = redirectUrl!;
    } else {
      yield call(notifySuccess);
    }
  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}


function* handeDebouncePutOrderAction() {
  yield delay(150);
  const order: OrderState = yield select((state: DormerRootState) =>
    state.order);

  if (order && order.isDirty) {
    yield call(handlePutOrder);

    // Call itself only if we did something last time.
    yield put(debouncePutOrderAction());
  }
}

export function* OrderSagas() {
  yield takeLatest(getOrderAction, handleGetOrder);
  yield takeLatest(putOrderAction, handlePutOrder);
  yield takeLatest(putCustomerInformationAction, handlePutCustomerInformation);
  yield throttle(500, debouncePutOrderAction, handeDebouncePutOrderAction);
}
