import produce from 'immer';
import {getOrderInvoiceAction, getOrderInvoiceSucceededAction, markDirtyOrderInvoiceAction,} from './Actions';
import {OrderInvoiceState} from './State';

export const getOrderInvoicesSucceededReducer = (state: OrderInvoiceState, action: ReturnType<typeof getOrderInvoiceSucceededAction>) => {
  return produce(state, (draft) => {
    draft.isDirty = false;
    draft.orderInvoice = action.payload.orderInvoice
  });
}

export const getOrderInvoiceReducer = (state: OrderInvoiceState, action: ReturnType<typeof getOrderInvoiceAction>) => {
  return produce(state, (draft) => {
    draft.isDirty = true;
  });
}

export const markDirtyOrderInvoiceReducer = (state: OrderInvoiceState, action: ReturnType<typeof markDirtyOrderInvoiceAction>) => {
  return produce(state, (draft) => {
    draft.isDirty = true;
  });
}


