import {
  getOrderInvoiceSucceededAction,
  getOrderInvoiceAction,
} from './index';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {getApplicationService, getOrderSelector, storeErrorAction} from "../../";
import {GetOrderInvoiceViewModel, OrderDto} from "@hec/api-dtos";
import {OrderInvoiceService} from "./Services";

function* handleGetOrderInvoices() {
  const service: OrderInvoiceService = yield getApplicationService(OrderInvoiceService);
  const order: OrderDto | null = yield select(getOrderSelector);

  if (order) {
    try {

      const result: GetOrderInvoiceViewModel = yield call([service, 'get'], order.id, order.configurationCode);
      yield put(getOrderInvoiceSucceededAction({ orderInvoice: result}));
    } catch (error) {
      yield put(storeErrorAction({error: error}));
    }
  }
}

export function* OrderInvoicesSagas() {
  yield takeLatest(getOrderInvoiceAction, handleGetOrderInvoices);
}
