import React, {FunctionComponent, useEffect, useState} from "react";
import styles from './material.module.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  SidebarFooter,
  MaterialAndColorSelectionDropDown,
  SelectionForSectionFragment
} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {TranslationKeys} from "@hec/models";
import {
  DormerRootState,
  getClientInformationSelector,
  getConfiguredSectionFragmentSelector, getSelectedConfigurationTemplateSelector,
  UpdateSectionFragmentDormerColorActionProps, updateSectionFragmentDormerColorsAction
} from "@hec/dal";
import {ExtraInformationForKeyRenderer} from "../../components";
import {
  CheeksDto,
  ConfigurationTemplateDto,
  DormerColorViewModel,
  FrameDto, FrameSurroundingRodDto, FrameVariation,
  ModifyGroupIndex,
  SectionFragmentType,
  StubbedDto
} from '@hec/api-dtos';
import {useCustomTranslation} from "@hec/dal";


export const MaterialSideBarContent: FunctionComponent = () => {
  const { t } = useCustomTranslation();
  const dispatch = useDispatch();

  const clientInformation = useSelector(getClientInformationSelector);
  const selectedConfigurationTemplate: ConfigurationTemplateDto | null = useSelector(getSelectedConfigurationTemplateSelector);
  const modifyGroupsAllowedToSeparate: ModifyGroupIndex[] | null = selectedConfigurationTemplate?.modifyGroupsAllowedToSeparate ?? null;
  const sectionFragmentTypesAllowedToSeparate: SectionFragmentType[] | null = selectedConfigurationTemplate?.sectionFragmentTypesAllowedToSeparate ?? null;

  const nextRoutePath = RoutePaths.ExtrasPath;
  const nextRouteButtonText = t(TranslationKeys.dormer.pages.extrasPage.cta);

  const [dirtyDormerColorsForSectionFragmentId, setDirtyDormerColorsForSectionFragmentId] = useState<UpdateSectionFragmentDormerColorActionProps[]>([]);

  const frames: FrameDto[] = useSelector((state: DormerRootState) => getConfiguredSectionFragmentSelector(state, SectionFragmentType.Frame)) as FrameDto[];
  // frames = frames.filter(x=> x.variation !== FrameVariation.Window);
  const cheeks: CheeksDto[] = useSelector((state: DormerRootState) => getConfiguredSectionFragmentSelector(state, SectionFragmentType.Cheeks)) as CheeksDto[];
  const stubbeds: StubbedDto[] = useSelector((state: DormerRootState) => getConfiguredSectionFragmentSelector(state, SectionFragmentType.Stubbed)) as StubbedDto[];
  const frameSurroundingRod: StubbedDto[] = useSelector((state: DormerRootState) => getConfiguredSectionFragmentSelector(state, SectionFragmentType.FrameSurroundingRod)) as FrameSurroundingRodDto[];

  const adjustableMaterials = [...cheeks, ...stubbeds, ...frames, ...frameSurroundingRod];

  const dispatchDirtyState = () => {
    const action = updateSectionFragmentDormerColorsAction({changes: dirtyDormerColorsForSectionFragmentId});
    dispatch(action);
  }

  useEffect(() => {
    setTimeout(() => {
      dispatchDirtyState()
    }, 15);
  }, [dirtyDormerColorsForSectionFragmentId, dispatchDirtyState])


  if (clientInformation === null) {
    return <></>;
  }


  const handeSelectedColorChanged = (selectionForSectionFragment: SelectionForSectionFragment) => {
    const sectionFragmentIds = Object.keys(selectionForSectionFragment);

    sectionFragmentIds.forEach((sfId) => {
      const sectionFragment = adjustableMaterials.find(x=> x.id == sfId || x.tempId == sfId);

      const selectedColorId = selectionForSectionFragment[sfId];

      if(sectionFragment && selectedColorId) {
        const allAvailableMaterials = (sectionFragment?.copiedFrom?.availableDormerMaterials ?? sectionFragment.availableDormerMaterials);
        const allAvailableColors = allAvailableMaterials.map(x=> x.dormerColors).flatMap<DormerColorViewModel>(x=> x);

        const selectedColorModel = allAvailableColors.find(x=> x.id == selectedColorId)

        if(selectedColorModel && sectionFragment.configuredDormerColorId !== selectedColorId) {
          setDirtyDormerColorsForSectionFragmentId((old) => {
            old = old.filter(x=> x.id !== sfId);

            const action: UpdateSectionFragmentDormerColorActionProps = {color: selectedColorModel, id: sfId}

            old.push(action);

            return [...old];
          });
        }
      }

    });

  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'MaterialsStepExtraInformation'}
        />
        <MaterialAndColorSelectionDropDown
          sectionFragments={adjustableMaterials}
          selectedColorChanged={handeSelectedColorChanged}
          deGroupableModifyGroups={modifyGroupsAllowedToSeparate ?? []}
          deGroupableTypes={sectionFragmentTypesAllowedToSeparate ?? []}
        />
      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.FramesPath}
        nextRoutePath={nextRoutePath}
        nextRouteCTA={nextRouteButtonText}
        clientInformation={clientInformation}
        onGoNext={dispatchDirtyState}
        onGoPrev={dispatchDirtyState}
      />
    </>
  );
};
