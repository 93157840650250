import { SoftShadows, useDetectGPU } from '@react-three/drei';
import {
  Suspense,
  FunctionComponent,
  CSSProperties,
  useMemo,
  memo,
} from 'react';
import { InnerRenderer } from './InnerRenderer';
import { DEFAULT_ZOOM } from './Camera';
import {
  IsExtraProduct,
  IsCladdingProduct,
  IsFrameProduct,
  Product,
} from '@hec/models';
import { HomeExtensionDto } from './HomeExtensionDto';
import { DEFAULT_WALL_HEIGHT } from '@hec/core';
import {Conditional} from "../Conditional";

export interface ExtensionsRendererProps {
  style?: CSSProperties;
  className?: string;
  zoom?: number;
  widthInMeters?: number;
  depthInMeters?: number;
  heightInMeters?: number;
  products?: Product[];
}
const MemoizedInnerRenderer = memo(InnerRenderer);
export const ExtensionsRenderer: FunctionComponent<ExtensionsRendererProps> = ({
  style,
  className,
  zoom = DEFAULT_ZOOM,
  products = [],
  widthInMeters,
  depthInMeters,
  heightInMeters = DEFAULT_WALL_HEIGHT,
}) => {
  const detectedGpu = useDetectGPU();
  const lowPerformanceMode = detectedGpu.tier < 2;
  // if (detectedGpu.tier === 3) {
  //   // If device is very performant use soft shadows
  //   // softShadows();
  // }
  const dto = useMemo(() => {
    const claddingProduct = products.find(IsCladdingProduct);
    const frameProduct = products.find(IsFrameProduct);

    if (!claddingProduct) {
      throw new (Error as any)('Cannot render home extension without cladding');
    }

    const extras = products.filter(IsExtraProduct);
    return new HomeExtensionDto(
      widthInMeters,
      depthInMeters,
      heightInMeters,
      claddingProduct,
      frameProduct,
      extras
    );
  }, [products, widthInMeters, depthInMeters, heightInMeters]);

  return (
    <Suspense fallback={<></>}>
      {/*<Conditional condition={detectedGpu.tier === 3}>*/}
      {/*  <SoftShadows/>*/}
      {/*</Conditional>*/}
      <MemoizedInnerRenderer
        zoom={zoom}
        className={className}
        lowPerformanceMode={lowPerformanceMode}
        style={style}
        homeExtensionDto={dto}
      />
    </Suspense>
  );
};
