import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  debouncePutOrderAction,
  getApplicationService,
  getOrderInvoiceAction,
  getOrderSelector,
  getSurveyAnswersAction,
  getSurveyAnswersSucceededAction,
  markDirtyOrderInvoiceAction,
  markOrderDirtyAction,
  putSurveyAnswersAction,
  putSurveyAnswersSucceededAction,
  storeErrorAction
} from "../../";
import {OrderDto} from "@hec/api-dtos";
import {SurveyAnswerViewModel, SurveyQuestionViewModel} from "@hec/models";
import {SurveyAnswerService, SurveyQuestionService} from './Services';
import {getSurveyQuestionsAction, getSurveyQuestionsSucceededAction} from './Actions';

function* handleGetSurveyQuestions(action: ReturnType<typeof getSurveyQuestionsAction>) {
  try {
    const configurationTemplateId: string = action.payload.configurationTemplateId;
    const service: SurveyQuestionService = yield getApplicationService(SurveyQuestionService);

    const orderDto: OrderDto = yield select(getOrderSelector);

    if (!orderDto.configurationCode) {
      yield put(storeErrorAction({
        error: new Error('No order to ask questions for (no configurationCode available)')
      }));
      return;
    }

    const configurationCode = orderDto.configurationCode;
    const result: SurveyQuestionViewModel[] = yield call([service, 'get'], configurationTemplateId, configurationCode);
    yield put(getSurveyQuestionsSucceededAction({surveyQuestionViewModels: result}));

  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}

function* handleGetSurveyAnswers(action: ReturnType<typeof getSurveyAnswersAction>) {
  try {
    const service: SurveyAnswerService = yield getApplicationService(SurveyAnswerService);

    const orderDto: OrderDto = yield select(getOrderSelector);

    if (!orderDto.configurationCode) {
      yield put(storeErrorAction({
        error: new Error('No order to ask questions for (no configurationCode available)')
      }));
      return;
    }

    const configurationCode = orderDto.configurationCode;
    const orderId = orderDto.id;
    const result: SurveyAnswerViewModel[] = yield call([service, 'get'], orderId, configurationCode);
    yield put(getSurveyAnswersSucceededAction({surveyAnswerViewModels: result}));

  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}


function* handlePutSurveyAnswers(action: ReturnType<typeof putSurveyAnswersAction>) {
  try {
    const orderId: string = action.payload.orderId;
    const service: SurveyAnswerService = yield getApplicationService(SurveyAnswerService);

    const orderDto: OrderDto = yield select(getOrderSelector);

    if (!orderDto.configurationCode) {
      yield put(storeErrorAction({
        error: new Error('No order to ask questions for (no configurationCode available)')
      }));
      return;
    }

    const configurationCode = orderDto.configurationCode;

    const result: SurveyAnswerViewModel[] = yield call([service, 'put'], orderId, configurationCode, action.payload.surveyAnswerViewModels);

    yield put(markDirtyOrderInvoiceAction());
    yield put(getOrderInvoiceAction());

    yield put(putSurveyAnswersSucceededAction({surveyAnswerViewModels: result}));
    yield put(debouncePutOrderAction());
  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}


export function* SurveyQuestionsSagas() {
  yield takeLatest(getSurveyQuestionsAction, handleGetSurveyQuestions);
  yield takeLatest(getSurveyAnswersAction, handleGetSurveyAnswers);
  yield takeLatest(putSurveyAnswersAction, handlePutSurveyAnswers);
}
