import {
  useTranslation,
  UseTranslationResponse,
  Namespace,
  KeyPrefix,
  DefaultNamespace,
  UseTranslationOptions,
} from 'react-i18next';
import {useSelector} from "react-redux";
import {getClientInformationSelector} from "../";

/// Totally not LLm generated lmfao.

// Define the type for the custom `t` method to include an optional default string
type CustomTFunction = (
  key: string,
  defaultValue?: string,
) => string;

// Extend the UseTranslationResponse type to include the modified `t` function
interface CustomUseTranslationResponse<TKPrefix extends KeyPrefix<'translation'>>
  extends Omit<UseTranslationResponse<'translation', TKPrefix>, 't'> {
  t: CustomTFunction;
}

// Custom hook that wraps the original useTranslation
export function useCustomTranslation<
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
>(
  options?: UseTranslationOptions<TKPrefix>,
): CustomUseTranslationResponse<TKPrefix> {
  const { t, ...rest } = useTranslation<'translation', TKPrefix>('translation', options);

  const clientInformation = useSelector(getClientInformationSelector);
  const configurationId = clientInformation?.clientConfigurationId;


  // Customized `t` function that uses the second argument as a fallback value
  const customT: CustomTFunction = (key, defaultValue) => {
    const confBasedKey = `${configurationId}.${key}`;
    return t(confBasedKey, t(key, { defaultValue }));
  };

  return { t: customT, ...rest };
}

